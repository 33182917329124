import { useStaticQuery, graphql } from 'gatsby';
import { isDummyOrTestContent } from '../../functions/common';

export const getCIARdata = () => {
  const x = useStaticQuery<GatsbyTypes.ciardataQuery>(graphql`
    query ciardata {
      allNodeCinpLocation(sort: {fields: title, order: ASC}) {
        nodes {
          title
          field_kar_latitude
          field_kar_longitude
          field_kar_status
          field_kar_additional_comments {
            value
          }
        }
      }
    }
  `);

const filteredNodes = x.allNodeCinpLocation.nodes.filter((node) => !isDummyOrTestContent(node.title)),
result = {
  allNodeCinpLocation: {
   nodes: filteredNodes,
 },
}
return result;
};
